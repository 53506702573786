import React from 'react';
import '../Styles/common.scss';
import 'antd/dist/antd.min.css';
import '../Styles/not-found.scss';
import { Typography, Card, Layout, Breadcrumb, BackTop } from 'antd';

const { Title } = Typography;
const { Content } = Layout;

export const NotFound: React.FC = () => {
    return (
        <>
            <Content className='content-body'>
                <Breadcrumb separator='>' className='bread-crumb'>
                    <Breadcrumb.Item>
                        <a href='/'>Top</a>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Card className='card-item'>
                    <Title>
                        404 Not Found
                    </Title>
                    <Title level={3}>
                        指定されたページは存在しません。
                    </Title>
                </Card>
            </Content>
            <BackTop />
        </>
    );
};
