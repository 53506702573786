import { Layout } from 'antd';
import '../Styles/header.scss';

const { Header } = Layout;

export const CustomHeader = () => {
    return (
        <Header>
            <a href='/'>
                <img
                    src={`${process.env.PUBLIC_URL}/Logo_pluszero.png`}
                    alt='Logo_pluszero'
                />
            </a>
        </Header>
    );
};