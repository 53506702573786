import React, { useState, useEffect } from 'react';
import { Layout, Typography, Card, Row, Col, Table, Button, DatePicker, BackTop, Breadcrumb } from 'antd';
import type { ColumnsType, TableProps } from 'antd/lib/table';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { Moment } from 'moment';
import 'antd/dist/antd.min.css';

import '../Styles/common.scss';
import { useQuery } from '@tanstack/react-query';
import { Params, useNavigate, useParams } from 'react-router-dom';

const { Content } = Layout;
const { Title } = Typography;

type DataTypeBacklog = {
    key: string;
    manager_mail: string;
};

type DataTypeMember = {
    key: React.Key;
    name: string;
    estimatedWorkingTime: number;
    estimatedHourlyWage: number;
    estimatedMoneyAmount: number;
};

type ProjectBase = {
    project_name: string;
    department: string;
    contract_status: string;
};

const columnBacklog: ColumnsType<DataTypeBacklog> = [
    {
        title: '名前',
        dataIndex: 'backlog_name',
        width: '33%',
    },
    {
        title: 'マネージャー',
        dataIndex: 'manager_mail',
        width: '33%'
    },
    {
        title: 'リンク',
        width: '33%',
        render: (_, record, index) => (
            <a key={index} href={`https://unipro.backlog.jp/projects/${record.key}`} target="blank">
                {record.key}
            </a>
        ),
    },
];

export const Projects = () => {
    const navigation = useNavigate();

    const columnMember: ColumnsType<DataTypeMember> = [
        {
            title: '名前',
            dataIndex: 'name',
            width: '22.5%',
            render: (_, record) => (
                <a key={record.key} onClick={() => navigation(`/member/${record.name}`)}>
                    {record.name}
                </a>
            ),
        },
        {
            title: '見積稼働時間',
            dataIndex: 'estimatedWorkingTime',
            width: '22.5%',
        },
        {
            title: '見積時給',
            dataIndex: 'estimatedHourlyWage',
            width: '22.5%',
        },
        {
            title: '見積金額',
            dataIndex: 'estimatedMoneyAmount',
            width: '22.5%',
        },
        {
            title: '確認',
            width: '10%',
            render: (_, record, index) => (
                <Button key={index} href={`/member/${record.name}`} type='default'>
                    確認
                </Button>
            ),
        },
    ];

    const pathParams: Params<string> = useParams();

    const [baseData, setBaseData] = useState<ProjectBase>({
        project_name: '',
        department: '',
        contract_status: '',
    });
    const [backlogData, setBacklogData] = useState<DataTypeBacklog[]>([]);
    const [memberData, setMemberData] = useState<DataTypeMember[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showError, setShowError] = useState<boolean>(false);

    const [date, setDate] = useState<string>(() => {
        const now = new Date();
        return `${now.getFullYear()}/${now.getMonth().toString().padStart(2, '0')}/01`;
    });

    useQuery([`project-${pathParams.name}-base`], async () => {
        const url = `${process.env.REACT_APP_GET_PROJECTS}/${pathParams.name}`;
        const params: RequestInit = {
            method: 'GET',
            mode: 'cors',
        };

        return await fetch(url, params)
            .then((res) => res.json())
            .then((data: string[]) => {
                const processedResult = {
                    project_name: data[3],
                    department: data[5],
                    contract_status: data[7],
                };
                return processedResult;
            });
    },
        {
            onSuccess: (data: ProjectBase) => {
                setBaseData(data);
                setLoading(false);
            },
            staleTime: Infinity,
        }
    );

    useQuery([`project-${pathParams.name}-members`], async () => {
        const url = `${process.env.REACT_APP_GET_PLANS}/projects/${pathParams.name}`;
        const params: RequestInit = {
            method: 'GET',
            mode: 'cors',
        };
        return await fetch(url, params)
            .then((res) => res.json())
            .then((data) => {
                let processed: DataTypeMember[] = [];
                for (let key in data) {
                    if (key === date) {
                        processed = data[key].map((item: {
                            member_name: string;
                            wages: string;
                            estimated_hours: string;
                            estimated_money_amount: string;
                        }, index: number) => {
                            return {
                                key: index,
                                name: item.member_name,
                                estimatedWorkingTime: item.estimated_hours,
                                estimatedHourlyWage: item.wages,
                                estimatedMoneyAmount: item.estimated_money_amount,
                            };
                        });
                    }
                }
                return processed;
            });
    },
        {
            onSuccess: (data) => {
                setMemberData(data);
                setLoading(false);
            },
            staleTime: Infinity,
        }
    );

    useQuery([`project-${pathParams.name}-backlogs`], async () => {
        const url = `${process.env.REACT_APP_BACKLOG}/projects/backlog`;

        const params: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: pathParams.name,
            }),
            mode: 'cors',
        };

        return await fetch(url, params)
            .then((res) => res.json())
            .then((data) => {
                return data;
            });
    },
        {
            onSuccess: (data) => {
                setLoading(false);
                setBacklogData(data);
            },
            staleTime: Infinity,
        }
    );

    useEffect(() => {
        // よい方法があったら代替する
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    const onChangeMonth = (_: Moment | null, dateString: string) => {
        const processedDate = dateString.replace('-', '/') + '/01';
        setDate(processedDate);
    };

    const rowWidth: number = 600;

    const tablePropsBacklog: TableProps<DataTypeBacklog> = {
        loading
    };

    const tablePropsMember: TableProps<DataTypeMember> = {
        loading
    };

    return (
        <>
            <Layout>
                <Content className='content-body'>
                    <Breadcrumb separator='>' className='bread-crumb'>
                        <Breadcrumb.Item>
                            <a onClick={() => navigation('/')}>Top</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            プロジェクト
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <Typography>
                        <Title level={3}>案件詳細</Title>
                    </Typography>
                    <Card
                        title={<Title level={4}>基本情報</Title>}
                        className='card-item'
                    >
                        <Row justify='start' style={{ width: rowWidth }}>
                            <Col span={12}>プロジェクト</Col>
                            <Col span={12}>{baseData.project_name}</Col>
                        </Row>
                        <Row justify='start' style={{ width: rowWidth }}>
                            <Col span={12}>部門</Col>
                            <Col span={12}>{baseData.department}</Col>
                        </Row>
                        <Row justify='start' style={{ width: rowWidth }}>
                            <Col span={12}>契約ステータス</Col>
                            <Col span={12}>{baseData.contract_status}</Col>
                        </Row>
                    </Card>
                    <Card
                        title={<Title level={4}>Backlog一覧</Title>}
                        className='card-item'
                    >
                        <Table
                            {...tablePropsBacklog}
                            columns={columnBacklog}
                            dataSource={backlogData}
                            size='middle'
                            pagination={{ position: ['bottomCenter'] }}
                            style={{ width: 1600 }}
                        />
                        <Button type='primary' href={`/backlog/${pathParams.name}`} size={'large'}>追加</Button>
                    </Card>
                    <Card
                        title={<Title level={4}>人員一覧</Title>}
                        className='card-item'
                    >
                        <DatePicker picker='month' onChange={onChangeMonth} locale={locale} placeholder='月選択'></DatePicker>
                        <div className='alert-message'>
                            {showError ? 'プロジェクト情報取得APIの呼び出しに失敗しました。\n問題が解決しない場合、管理者に問い合わせてください。' : ''}
                        </div>
                        <Table
                            {...tablePropsMember}
                            columns={columnMember}
                            dataSource={memberData}
                            size='middle'
                            pagination={{ position: ['bottomCenter'] }}
                            style={{ width: 1600 }}
                        />
                    </Card>
                </Content>
                <BackTop />
            </Layout>
        </>
    );
};
