import { useEffect, useState } from 'react';
import { Card, Layout, Typography, Table, DatePicker, BackTop, Breadcrumb, Row, Col, Tag } from 'antd';

import 'antd/dist/antd.min.css';
import '../Styles/common.scss'

import { ColumnsType, TableProps } from 'antd/lib/table';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { useMutation, useQuery } from '@tanstack/react-query';
import moment, { Moment } from 'moment';
import { Params, useNavigate, useParams } from 'react-router-dom';

const { Content } = Layout;
const { Title } = Typography;

type MemberBase = {
    name: string;
    id: string;
    employment_type: string;
    hourly_wage: number;
    is_employed: string;
    created_at: string;
    ai_skills: string[];
};

type MemberAssigned = {
    department: string;
    project_name: string;
    member_name: string;
    date: string;
    estimated_hours: number;
    wages: number;
    budget: number;
};

export const Member = () => {
    // クエリパラメータから人員名を得る
    const pathParams: Params<string> = useParams();

    // 人員の基本情報
    const [memberBaseInfo, setMemberBaseInfo] = useState<MemberBase | null>(null);

    // 人員のアサインされている稼働管理情報
    const [memberAssign, setMemberAssign] = useState<MemberAssigned[]>([]);

    // 閲覧する稼働管理の年月
    const [dateTo, setDateTo] = useState<string>(() => {
        const now: Date = new Date();
        return `${now.getFullYear()}/${(now.getMonth() + 1).toString().padStart(2, '0')}/01`;
    });

    const [formDateTo, setFromDateTo] = useState<Moment>(moment(dateTo));

    const [dateFrom, setDateFrom] = useState<string>(() => {
        const now: Date = new Date();
        return `${now.getFullYear()}/${(now.getMonth() - 5).toString().padStart(2, '0')}/01`;
    });

    const [formDateFrom, setFormDateFrom] = useState<Moment>(moment(dateFrom));

    const [term, setTerm] = useState<string[]>(makeTerm(dateFrom, dateTo));

    // ロード中か否かを判定
    const [loading, setLoading] = useState<boolean>(true);

    const [showError, setShowError] = useState<boolean>(false);

    const [assignColumns, setAssginColumns] = useState<any[]>([]);

    const baseUrl: string | undefined = process.env.REACT_APP_GET_MEMBERS;
    const baseParams: RequestInit = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors',
    };
    useQuery([`member-${pathParams.name}-base`], async () => {
        return await fetch(`${baseUrl}/${pathParams.name}`, baseParams)
            .then(res => res.json())
            .then(data => {
                return data;
            });
    }, {
        onSuccess: (data) => {
            setLoading(false);
            setMemberBaseInfo(data);
        },
        staleTime: Infinity,
    });

    const assignUrl: string | undefined = process.env.REACT_APP_GET_PLANS;
    const assignParams: RequestInit = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            name: pathParams.name,
            from: dateFrom,
            to: dateTo,
        }),
        mode: 'cors',
    };
    useQuery([`member-${pathParams.name}-assign`], async () => {
        return await fetch(`${assignUrl}/members/${pathParams.name}`, assignParams)
            .then(res => res.json())
            .then(data => {
                return data;
            });
    }, {
        onSuccess: (data) => {
            setLoading(false);
            setMemberAssign(data);
        },
        staleTime: Infinity,
    });
    const mutateAssign = useMutation(async () => {
        return await fetch(`${assignUrl}/members/${memberBaseInfo?.name}`, assignParams)
            .then(res => res.json())
            .then(data => {
                return data;
            });
    }, {
        onSuccess: (data) => {
            setMemberAssign(data);
        }
    });

    const navigation = useNavigate();

    useEffect(() => {
        // よい方法があったら代替する
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    useEffect(() => {
        mutateAssign.mutate();
        setAssginColumns(createAssignColumns(term));
    }, [term]);

    /**
     * DatePickerコンポーネントで年月が変更された場合に呼ばれる処理
     * @param {Moment} _ - 使用しない
     * @param {string} dateString - 選択された日付を表す文字列
     */
    const onChangeMonthFrom = (_: Moment | null, dateString: string) => {
        setDateFrom(`${dateString}-01`);
        setTerm(makeTerm(`${dateString}-01`, dateTo));
    };
    const onChangeMonthTo = (_: Moment | null, dateString: string) => {
        setDateTo(`${dateString}-01`);
        setTerm(makeTerm(dateFrom, `${dateString}-01`));
    };

    const createAssignColumns = (term: string[]) => {
        const columns: ColumnsType<any> = [];

        columns.push({
            title: 'プロジェクト名',
            dataIndex: 'project_name',
            width: 200,
            render: (_, record: MemberAssigned) => (
                record.project_name === '総計' ?
                    <div>{record.project_name}</div> :
                    <a key={record.project_name} onClick={() => navigation(`/project/${record.project_name}`)}>
                        {record.project_name}
                    </a>
            ),
        });

        term.forEach((month: string) => {
            columns.push({
                title: month,
                dataIndex: month,
                width: 100,
            });
        });

        columns.push({
            title: '合計',
            dataIndex: 'operating_time',
            width: 100,

        });

        return columns;
    };

    const rowWidth: number = 600;

    const tablePropsAssign: TableProps<MemberAssigned> = {
        loading
    };

    return (
        <>
            <Layout>
                <Content className='content-body'>
                    <Breadcrumb separator='>' className='bread-crumb'>
                        <Breadcrumb.Item>
                            <a href='/'>Top</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            メンバー
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <Typography>
                        <Title level={3}>人員管理</Title>
                    </Typography>
                    <Card
                        className='card-item'
                        title={<Title level={3}>基本情報</Title>}
                    >
                        <Row justify='start' style={{ width: rowWidth }}>
                            <Col span={12}>名前</Col>
                            <Col span={12}>{memberBaseInfo?.name}</Col>
                        </Row>
                        <Row justify='start' style={{ width: rowWidth }}>
                            <Col span={12}>雇用形態</Col>
                            <Col span={12}>{memberBaseInfo?.employment_type}</Col>
                        </Row>
                        <Row justify='start' style={{ width: rowWidth }}>
                            <Col span={12}>単価</Col>
                            <Col span={12}>{memberBaseInfo?.hourly_wage} 円</Col>
                        </Row>
                        <Row justify='start' style={{ width: rowWidth }}>
                            <Col span={12}>在籍フラグ</Col>
                            <Col span={12}>{memberBaseInfo?.is_employed}</Col>
                        </Row>
                        <Row justify='start' style={{ width: rowWidth }}>
                            <Col span={12}>作成日</Col>
                            <Col span={12}>{memberBaseInfo?.created_at}</Col>
                        </Row>
                        <Row justify='start' style={{ width: rowWidth }}>
                            <Col span={12}>AIスキル</Col>
                            <Col span={12}>
                                {memberBaseInfo?.ai_skills ?
                                    memberBaseInfo.ai_skills
                                        .map((ai_skill: string) => {
                                            return <Tag key={ai_skill} color='green'>{ai_skill}</Tag>
                                        })
                                    : <></>}
                            </Col>
                        </Row>
                    </Card>
                    <Card className='card-item' title={<Title level={3}>稼働管理</Title>}>
                        <DatePicker picker='month' onChange={onChangeMonthFrom} locale={locale} placeholder='開始月' defaultValue={formDateFrom} allowClear={false}></DatePicker>
                        <DatePicker picker='month' onChange={onChangeMonthTo} locale={locale} placeholder='終了月' defaultValue={formDateTo} allowClear={false}></DatePicker>
                        <div className='alert-message'>
                            {showError ? '人員情報取得APIの呼び出しに失敗しました。\n問題が解決しない場合、管理者に問い合わせてください。' : ''}
                        </div>
                        <Table
                            {...tablePropsAssign}
                            dataSource={memberAssign}
                            columns={assignColumns}
                            size='middle'
                            style={{ width: 1500 }}
                            bordered
                            pagination={false}
                            scroll={{ x: '1500', y: '300' }}
                        />
                    </Card>
                </Content>
                <BackTop />
            </Layout>
        </>
    );
};

const makeTerm = (dateFrom: string, dateTo: string): string[] => {
    const list: string[] = [];
    const countDateFrom: Date = new Date(dateFrom);
    const countDateTo: Date = new Date(dateTo);

    // 念のため最大件数を設定する
    for (let i = 0; i < 20; i++) {
        list.push(`${countDateFrom.getFullYear()}/${(countDateFrom.getMonth() + 1).toString().padStart(2, '0')}/01`);
        countDateFrom.setMonth(countDateFrom.getMonth() + 1);
        if (countDateFrom > countDateTo) {
            break;
        }
    }
    return list;
};