import React, { useState, useEffect } from 'react';
import { Typography, Card, Table, Tag, Input, Cascader } from 'antd';
import { useNavigate } from 'react-router-dom';
import type { ColumnsType, TableProps } from 'antd/lib/table';
import 'antd/dist/antd.min.css';
import '../Styles/member-list.scss';
import { useQuery } from '@tanstack/react-query';
import { SingleValueType } from 'rc-cascader/lib/Cascader';
import { DefaultOptionType } from 'antd/lib/select';

const { Title, Paragraph } = Typography;

type Member = {
    key: string;
    id: string;
    name: string;
    employment_status: string;
    enrollment: string;
    ai_skills: string[];
};

type employmentOption = {
    value: string;
    label: string;
    enrollment: string;
};

type skillOption = {
    value: string | number;
    label: string;
};

const columns: ColumnsType<Member> = [
    {
        title: '名前',
        dataIndex: 'name',
        width: '20%',
    },
    {
        title: '契約形態',
        dataIndex: 'employment_status',
        width: '20%',
    },
    {
        title: 'スキル',
        dataIndex: 'ai_skills',
        width: '15%',
        render: (_, record: Member, index: number) => {
            return (
                <div key={index}>
                    {record.ai_skills.map((skill: string, index: number) => {
                        if (skill) {
                            return (<Tag key={index} color='green'>{skill}</Tag>);
                        } else {
                            return null;
                        }
                    })}
                </div>
            );
        },
    },
];

const employmentOptions: employmentOption[] = [
    {
        value: '正社員',
        label: '正社員',
        enrollment: '',
    },
    {
        value: 'パートタイマー',
        label: 'パートタイマー',
        enrollment: '',
    },
    {
        value: '業務委託契約者',
        label: '業務委託契約者',
        enrollment: '',
    },
    {
        value: '取締役',
        label: '取締役',
        enrollment: '',
    },
    {
        value: '管理監督者',
        label: '管理監督者',
        enrollment: '',
    },
];

const skillOptions: skillOption[] = [
    {
        label: 'データ分析',
        value: 'データ分析',
    },
    {
        label: '画像認識',
        value: '画像認識',
    },
    {
        label: 'NLP',
        value: 'NLP',
    },
];

export const MemberList: React.FC = () => {
    const navigate = useNavigate();

    const [memberDataMaster, setMemberDataMaster] = useState<Member[]>([]);
    const [memberData, setMemberData] = useState<Member[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showError, setShowError] = useState<boolean>(false);

    const { isLoading, error, data } = useQuery(['members'], async () => {
        let url: string = '';
        if (process.env.REACT_APP_GET_MEMBERS) {
            url = process.env.REACT_APP_GET_MEMBERS;
        }
        const params: RequestInit = {
            method: 'GET',
            mode: 'cors',
        };
        return await fetch(url, params)
            .then(res => res.json())
            .then(data => data).then(data => {
                // 見出しを削除
                data.shift();
                const processedResult: Member[] = [];
                data.map((item: Member, index: number) => {
                    if (item.enrollment === 'TRUE') {
                        processedResult.push({
                            key: index.toString(),
                            id: item.id,
                            name: item.name,
                            employment_status: item.employment_status,
                            enrollment: item.enrollment,
                            ai_skills: item.ai_skills,
                        });
                    }
                    return null;
                });
                setLoading(false);
                return processedResult;
            });
    },
        {
            staleTime: Infinity,
        }
    );

    useEffect(() => {
        setMemberDataMaster(data ? data : []);
        setMemberData(data ? data : []);
        // よい方法があったら代替する
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    }, [data]);

    /**
     * 雇用形態を選択した際、空の配列に選択した雇用形態が入る
     * @param employType - 選択した値
     */
    const selectEmploymentStatus = (employType: SingleValueType) => {
        const name: string = typeof employType[0] === 'string' ? employType[0] : '';
        const array: Member[] = [];
        memberDataMaster.forEach((employmentStatus: Member, index: number) => {
            if (employmentStatus.employment_status === name) {
                array.push({
                    key: `${employmentStatus.name}-${index}`,
                    id: employmentStatus.id,
                    name: employmentStatus.name,
                    employment_status: employmentStatus.employment_status,
                    enrollment: employmentStatus.enrollment,
                    ai_skills: employmentStatus.ai_skills,
                });
            }
        });
        setMemberData(array);
    };

    /**
     * スキルを選択した際、空の配列に選択したスキルが入る
     * @param value - 選択した値
     */
    const selectSkillStatus = (skills: DefaultOptionType[][]) => {
        setMemberData(memberDataMaster);

        const arraySkills: string[] = skills.map((item: DefaultOptionType[]) => {
            return typeof item[0].value === 'string' ? item[0].value : '';
        });

        if (arraySkills.length === 0) {
            return;
        }

        const arrayMembers: Member[] = [];

        memberDataMaster.map((member: Member, index: number) => {
            if (arraySkills.every((skill: string) => member.ai_skills.includes(skill))) {
                arrayMembers.push({
                    key: `${member.name}-${index}`,
                    id: member.id,
                    name: member.name,
                    employment_status: member.employment_status,
                    ai_skills: member.ai_skills,
                    enrollment: member.enrollment,
                });
            }
        });

        setMemberData(arrayMembers);
    };

    const TableProps: TableProps<Member> = {
        loading
    };

    /**
     * 人員一覧から入力された名前で表示する人員を絞り込む。
     * @param e - 入力された値。
     */
    const searchMember = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.value;
        const members: Member[] = [];
        memberDataMaster.map((member: Member, index: number) => {
            if (member.name.indexOf(name) >= 0) {
                members.push({
                    key: `${member.name}-${index}`,
                    id: member.id,
                    name: member.name,
                    employment_status: member.employment_status,
                    ai_skills: member.ai_skills,
                    enrollment: member.enrollment,
                });
            }
            return null;
        });
        setMemberData(members);
    };

    return (
        <>
            <Card title={<Title level={3}>人員一覧</Title>}>
                <Paragraph>雇用形態を選択してください</Paragraph>
                <Cascader
                    onChange={(value: SingleValueType, _: DefaultOptionType[]) => selectEmploymentStatus(value)}
                    options={employmentOptions}
                    placeholder='雇用形態'
                ></Cascader>
                <br />
                <br />
                <Paragraph>スキルを選択してください</Paragraph>
                <Cascader
                    placeholder='スキル'
                    style={{ width: '50%' }}
                    options={skillOptions}
                    onChange={(_: SingleValueType[], selectedOptions: DefaultOptionType[][]) => selectSkillStatus(selectedOptions)}
                    multiple
                    maxTagCount='responsive'
                ></Cascader>
                <br />
                <br />
                <Paragraph>絞り込みたい名前を入力してください</Paragraph>
                <Input onChange={searchMember}></Input>
                <br />
                <br />
                <div className='alert-message'>
                    {memberData.length <= 0 && !loading ? '該当データはありません' : ''}
                </div>
                <div className='alert-message'>
                    {showError ? '人員一覧取得APIの呼び出しに失敗しました。\n問題が解決しない場合、管理者に問い合わせてください。' : ''}
                </div>
                <Table
                    className='table-row'
                    {...TableProps}
                    columns={columns}
                    dataSource={memberData}
                    size='middle'
                    pagination={{ position: ['bottomCenter'] }}
                    style={{ width: 1000 }}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                navigate(`/member/${record.name}`);
                            },
                        };
                    }}
                />
            </Card>
        </>
    );
};
