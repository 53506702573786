import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { CustomHeader } from "./Components/Header";
import { CustomFooter } from "./Components/Footer";

import { Backlog } from "./Screens/Backlog";
import { Member } from "./Screens/Member";
import { Projects } from "./Screens/Project";
import { Top } from "./Screens/Top";
import { NotFound } from "./Screens/NotFound";

const App = () => {
	const queryClient = new QueryClient();

	return (
		<QueryClientProvider client={queryClient}>
			<BrowserRouter>
				<CustomHeader />
				<Routes>
					<Route path={'/'} element={<Top />} />
					<Route path={'/backlog/:name'} element={<Backlog />} />
					<Route path={'/member/:name'} element={<Member />} />
					<Route path={'/project/:name'} element={<Projects />} />
					<Route path='*' element={<NotFound />} />
				</Routes>
				<CustomFooter />
			</BrowserRouter>
		</QueryClientProvider>
	);
}
export default App;
