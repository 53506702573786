import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Styles/common.scss';
import { Typography, Table, Card, Input, Cascader } from 'antd';
import type { ColumnsType, TableProps } from 'antd/lib/table';
import 'antd/dist/antd.min.css';
import { useQuery } from '@tanstack/react-query';
import { DefaultOptionType } from 'antd/lib/select';
import { SingleValueType } from 'rc-cascader/lib/Cascader';

const { Title, Paragraph } = Typography;

type Project = {
    key: string;
    project_id: string;
    project_name: string;
    department: string;
    contract_status: string;
};

interface Option {
    value: string;
    label: string;
}

const columns: ColumnsType<Project> = [
    {
        title: '案件名',
        dataIndex: 'project_name',
        width: '30%',
    },
    {
        title: '部署',
        dataIndex: 'department',
        width: '30%',
    },
    {
        title: 'ステータス',
        dataIndex: 'contract_status',
        width: '30%',
    },
];

const options: Option[] = [
    {
        value: 'ソリューション開発',
        label: 'ソリューション開発',
    },
    {
        value: '研究開発',
        label: '研究開発',
    },
    {
        value: '事業・人材開発',
        label: '事業・人材開発',
    },
    {
        value: '営業',
        label: '営業',
    },
    {
        value: '経営管理',
        label: '経営管理',
    },
];

export const ProjectList: React.FC = () => {
    const navigate = useNavigate();

    const [projectDataMaster, setProjectDataMaster] = useState<Project[]>([]);
    const [projectData, setProjectData] = useState<Project[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showError, setShowError] = useState<boolean>(false);

    const { isLoading, error, data } = useQuery(['projects'], async () => {
        let url: string = '';
        if (process.env.REACT_APP_GET_PROJECTS) {
            url = process.env.REACT_APP_GET_PROJECTS;
        }
        const params: RequestInit = {
            method: 'GET',
            mode: 'cors',
        };
        return await fetch(url, params)
            .then((res) => {
                if (res.status !== 200) {
                    return [];
                }
                return res.json();
            })
            .then(data => {
                // 見出しを削除する
                data.shift();
                const processedResult: Project[] = data.map((item: Project, index: number) => {
                    return {
                        key: index.toString(),
                        project_id: item.project_id,
                        project_name: item.project_name,
                        department: item.department,
                        contract_status: item.contract_status,
                    };
                });
                setLoading(false);
                return processedResult;
            });
    },
        {
            staleTime: Infinity,
        }
    );

    useEffect(() => {
        /**
     * バックエンドのAPIを呼び出し、プロジェクトデータを受け取る。
     * @returns - プロジェクトのデータが格納された配列。
     */
        setProjectDataMaster(data ? data : []);
        setProjectData(data ? data : []);
        // よい方法があったら代替する
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    }, [data]);

    const selectDepartment = (value: SingleValueType) => {
        const name: string = typeof value[0] === 'string' ? value[0] : '';
        const array: Project[] = [];
        projectDataMaster.map((project: Project) => {
            if (project.department === name) {
                array.push({
                    key: project.project_id,
                    project_id: project.project_id,
                    project_name: project.project_name,
                    department: project.department,
                    contract_status: project.contract_status,
                });
            }
            return null;
        });
        setProjectData(array);
    };

    const TableProps: TableProps<Project> = {
        loading
    };

    /**
     * 入力欄の文字列でプロジェクトの一覧を絞り込む。
     */
    const searchName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name: string = e.target.value;
        const array: Project[] = [];
        projectDataMaster.map((project: Project) => {
            if (project.project_name.indexOf(name) >= 0) {
                array.push({
                    key: project.project_id,
                    project_id: project.project_id,
                    project_name: project.project_name,
                    department: project.department,
                    contract_status: project.contract_status,
                });
            }
            return null;
        });
        setProjectData(array);
    };

    return (
        <>
            <Card title={<Title level={3}>案件一覧</Title>}>
                <Paragraph>部署を選択してください</Paragraph>
                <Cascader
                    onChange={(value: SingleValueType, _: DefaultOptionType[]) => selectDepartment(value)}
                    options={options}
                    placeholder='部署'
                ></Cascader>
                <br />
                <br />
                <Paragraph>
                    絞り込みたいプロジェクト名を入力してください
                </Paragraph>
                <Input onChange={searchName}></Input>
                <br />
                <br />
                <div className='alert-message'>
                    {projectData.length <= 0 && !loading ? '該当データはありません' : ''}
                </div>
                <div className='alert-message'>
                    {showError ? 'プロジェクト一覧取得APIの呼び出しに失敗しました。\n問題が解決しない場合、管理者に問い合わせてください。' : ''}
                </div>
                <Table
                    {...TableProps}
                    className='table-row'
                    columns={columns}
                    dataSource={projectData}
                    size='middle'
                    pagination={{ position: ['bottomCenter'] }}
                    style={{ width: 1000 }}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                navigate(`/project/${record.project_name}`, { replace: true });
                            },
                        };
                    }}
                ></Table>
            </Card>
        </>
    );
};
