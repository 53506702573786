import React from 'react';
import { useAsyncFn } from 'react-use';
import { Params, useNavigate, useParams } from 'react-router-dom';

import { Card, Layout, Button, Input, Typography, Form, Modal } from 'antd';
import 'antd/dist/antd.min.css';

import { useForm } from 'antd/lib/form/Form';
import { QueryClient } from '@tanstack/react-query';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

// Backlogの新しいプロジェクトを作るための型
interface FormValues {
    backlogName: string;
    projectKey: string;
    chargedPerson: string;
}

export const Backlog: React.FC = () => {
    // リクエストパラメータから紐づけるプロジェクト名を得る
    const pathParams: Params<string> = useParams();

    // フォームをクリアするための処理
    const [form] = useForm();

    // 各種モーダルメッセージの定義
    const modalSuccess = () => {
        Modal.success({
            title: "プロジェクト追加に成功しました",
            content: ""
        });
    };

    const modalError = () => {
        Modal.error({
            title: "プロジェクト追加に失敗しました",
            content: "レスポンスを受け取れませんでした。\n管理者へ連絡してください。"
        });
    };

    const modalKeyError = () => {
        Modal.error({
            title: "KEYの値が不正です",
            content: "KEYに使用できる文字列は大文字アルファベット、数字、_(アンダースコア)のみです。"
        });
    };

    const validateMessages = {
        required: "このフォームは必須項目です",
        types: {
            email: "不正なメールアドレスです"
        }
    }

    const queryClient = new QueryClient();

    /**
     * フォームの送信ボタンを押した際の処理
     * バリデーションを通過したらバックエンドのAPIを呼ぶ
     */
    const [onFinish, onFinishFn] = useAsyncFn(async (values: FormValues) => {
        if (!values.projectKey.match(/^[A-Z0-9_]+$/g)) {
            console.error('KEYバリデーションエラー');
            modalKeyError();
            return;
        }

        let url: string = "";
        if (process.env.REACT_APP_BACKLOG) {
            url = `${process.env.REACT_APP_BACKLOG}/projects/backlog`;
        }
        const params: RequestInit = {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                key: values.projectKey,
                manager_mail: values.chargedPerson,
                backlog_name: values.backlogName,
                name: pathParams.name,
            }),
            mode: 'cors',
        };

        await fetch(url, params)
            .then((res) => {
                if (res.status != 200) {
                    modalError();
                    return;
                }
                modalSuccess();
                form.resetFields();
                queryClient.invalidateQueries([`project-${pathParams.name}-backlog`]);
            });
    });

    // フォームをクリアするボタンが押された際の処理
    const [onFormClear, onFormClearFn] = useAsyncFn(async () => {
        form.resetFields();
    });

    return (
        <>
            <Layout>
                <Content className="content-body">
                    <Typography>
                        <Title level={3}>Backlogプロジェクト作成</Title>
                    </Typography>
                    <Paragraph>
                        フォームを送信するとBacklogのプロジェクトを自動で作成します
                    </Paragraph>
                    <Form form={form} onFinish={onFinishFn} validateMessages={validateMessages}>
                        <Card title="Backlog名:" className="card-item">
                            <Form.Item name="backlogName" rules={[{ required: true }]}>
                                <Input placeholder="プロジェクト名" />
                            </Form.Item>
                        </Card>
                        <Card title="KEY:" className="card-item">
                            <Paragraph>
                                プロジェクトを一意に識別するためのキーです（例：「Backlog」＝「BLG_2」）。大文字（A-Z）、数字（0-9）、アンダースコア（_）が使用可能です。
                            </Paragraph>
                            <Form.Item name="projectKey" rules={[{ required: true }]}>
                                <Input placeholder="KEY" />
                            </Form.Item>
                        </Card>
                        <Card title="管理者メールアドレス:" className="card-item">
                            <Paragraph></Paragraph>
                            <Form.Item name="chargedPerson" rules={[{ type: "email", required: true }]}>
                                <Input placeholder="メールアドレス" />
                            </Form.Item>
                        </Card>
                        <Button type="primary" htmlType="submit" >送信</Button>
                        <Button type="default" onClick={onFormClearFn}>フォームをクリア</Button>
                    </Form>
                </Content>
            </Layout>
        </>
    );
};